import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../../assets/images/logo.png';
import './Navbar.css';
import DataContext from '../../DataContext';

const navList = ['Home', 'Why Cashpaddie', 'Contact'];

const Navbar: React.FC = () => {
  const location = useLocation();
  const contextValue:any = useContext(DataContext)
  let phoneNumber:any
  if(contextValue.data && contextValue.data.length > 0){
    contextValue.data.forEach((element:any) => {
      if(element.type === "dial"){
        phoneNumber = element.value;
      }
    });
  }
  
  return (
    <header className="navbar shadow">
      <div className="container flex justify-between align-center">
        <Link to="/" className="navbar__brand flex-inline align-center">
          <img className="navbar__logo" src={Logo} alt="logo" />
          <span className="navbar__title">Cashpaddie</span>
        </Link>
        <div className="flex">
          {location.pathname === '/' && (
            <nav className="navbar__menu">
              {navList.map((navItem, i) => (
                <a className="navbar__link" key={i} href={`#${navItem.split(' ').join('_')}`}>
                  {navItem}
                </a>
              ))}
            </nav>
          )}
          <div className="navbar__contact flex-inline align-center">
            <i className="ri-phone-fill contact-phone-icon"></i>
            <span className="contact-phone-number text-white">{phoneNumber}</span>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
