import React from 'react';
import './Product.css';
import SectionTitle from '../../SectionTitle';
import Reactangle from '../../../assets/images/Rectangle_product.png';
import Slice3 from '../../../assets/images/Slice_1.png';
import Slice2 from '../../../assets/images/Slice_2.png';
import Slice1 from '../../../assets/images/Slice_3.png';
import Slice4 from '../../../assets/images/Slice_4.png';
import Slice0 from '../../../assets/images/Slice_0.png';
interface ICard {
  img: string;
  description: string;
  detail: string;
}

interface CardProps extends ICard {}

const Card = ({ img, description, detail }: CardProps) => {
  return (
    <li className="card">
      <img className="card__img" src={img} alt="" />
      <div className="card__description">{description}</div>
      <div className="card__detail">{detail}</div>
    </li>
  );
};

const Product: React.FC = () => {
  const cards: ICard[] = [
    {
      img: Slice1,
      description: 'Easy to Apply',
      detail: 'A simplified application process.Apply online from start to finish without any paperwork.',
    },
    {
      img: Slice0,
      description: 'Fast Funding',
      detail: 'If approved,receive money in your account as soon as the next hour.',
    },
    {
      img: Slice2,
      description: 'Loans that fit your financial needs ',
      detail: 'We offer you loans up to ₦ 50,000 .',
    },
    {
      img: Slice3,
      description: 'Top-rated, personalized service',
      detail: 'We are invested in your success.We providing professional and quality customer service.',
    },
    {
      img: Slice4,
      description: 'No Hidden Fees',
      detail: 'We are committed to transparency. You will know what to expect.',
    },
  ];
  return (
    <section id="Why_Cashpaddie" className="product-section relative flex flex-column">
      <div className="product__bg-left"></div>
      <div className="product__bg-right"></div>
      <div className="container ">
        <SectionTitle title="Why Cashpaddie" />
        <ul className="product-list">
          {cards.map((data, i) => (
            <Card {...data} key={i} />
          ))}
        </ul>
        <div className="product-section__footer">
          <div>Simple | Secure | No hidden fees | Anywhere | Anytime</div>
          <div>In any emergency,just need you to provide a valid BVN and Android phone, you can get the loan.</div>
        </div>
      </div>
      <img className="product-section__footer-bg" src={Reactangle} alt="" />
    </section>
  );
};

export default Product;
