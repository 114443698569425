import axios from 'axios';
import { createEnv } from './server-config';

const service = axios.create({
  baseURL: createEnv().endpoint,
  timeout: 35 * 1000,
});

service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default service;
