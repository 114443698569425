import React from 'react'
import './Reminder.css'

const Reminder : React.FC = () => {
  return (
    <div className="reminder">
      <h3 className="reminder-title">Important Reminder</h3>
      <p className="reminder-subtitle">Please read the following reminders carefully.</p>
      <ul className="reminder-content">
        <li>
          <span>1</span>After your account is deleted, you will not be able to log in to the app, view your order data, or apply for newe
          loans.
        </li>
        <li>
          <span>2</span>After submitting a request to delete your account, the system will delete your account information within 7 days.
          During this period, you can return to this page to check the progress or cancel the deletion.
        </li>
        <li>
          <span>3</span>Once the account deletion operation is completed, you will never be able to recover your data.
        </li>
      </ul>
    </div>
  );
}

export default Reminder;