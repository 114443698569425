import React from 'react';
import styled from 'styled-components';
import { mediaQueries } from '../mediaQueries';
interface TitleProps {
  title: string;
  size?: string;
}

const TitleWrap = styled.h2`
  text-align: center;
  color: var(--title-color);
  font-size: 24px;
  ${() => mediaQueries('lg')(`font-size: 44px`)};
  ${() => mediaQueries('xl')(`font-size: 50px`)};
  ${() => mediaQueries('xxl')(`font-size: 60px`)};
`;

const TitleSpan = styled.span.attrs((props: { size: string }) => ({
  size: props.size,
}))`
  border-bottom: 2px solid var(--first-color);
  padding-bottom: 10px;
  display: inline-block;
  ${() => mediaQueries('md')(`border-bottom-width: 4px`)};
`;

const Title: React.FC<TitleProps> = (props) => {
  return (
    <TitleWrap>
      <TitleSpan {...props}>{props.title}</TitleSpan>
    </TitleWrap>
  );
};

export default Title;
