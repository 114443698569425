import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Application.css';
import Layout from '../components/Layout/Layout';
import Reminder from '../components/Reminder/Reminder';
import FormContainer from '../components/FormContainer';
import FormButton from '../components/FormButton';
import VCode from 'react-vcode';
import httpClient from '../../http-client';
import config from '../../config';
import { useNumberState } from '../../hooks/useNumberState';
import { Alert } from '../../alert';
import { createEnv } from '../../server-config';

const RequestForm: React.FC = () => {
  const navigate = useNavigate();
  const THE_COUNT_NUM = 60;
  const clientId = createEnv().clientId;
  const api = config.api.deleteAccount;
  const [mobile, setMobile] = useNumberState('');
  const [smsCode, setSmsCode] = useNumberState('');
  const [hasSendSmsCode, setHasSendSmsCode] = React.useState(false);
  const [smsSendButtonText, setSmsSendButtonText] = React.useState('Send Verification Code');
  const [vCodeInputValue, setVCodeInputValue] = React.useState('');
  const [vCodeValue, setVCodeValue] = React.useState<string | null>('');
  const [count, setCount] = React.useState(THE_COUNT_NUM);
  const [disabledSmsSend, setDisabledSmsSend] = React.useState(false);
  const [checked, setChecked] = React.useState(false);

  React.useEffect(() => {
    if (count > -1 && count < THE_COUNT_NUM) {
      setDisabledSmsSend(true);
      const timeout = setTimeout(() => {
        setCount((count) => count - 1);
        setSmsSendButtonText(`${count}s`);
      }, 1000);
      return () => clearTimeout(timeout);
    } else {
      setCount(THE_COUNT_NUM);
      hasSendSmsCode && setSmsSendButtonText('Resend verification code');
      setDisabledSmsSend(false);
    }
  }, [count, hasSendSmsCode]);

  const validateForm = () => {
    if (mobile.length === 0) {
      Alert.error('Please enter your mobile phone number.');
      return false;
    }

    if (mobile.length !== 10) {
      Alert.error('The phone number is incorrect.');
      return false;
    }

    if (vCodeInputValue.trim() !== vCodeValue) {
      Alert.error('Image verification code is incorrect.');
      return false;
    }

    return true;
  };

  const onVCodeSend = () => {
    // console.log('send');

    if (!validateForm()) {
      return false;
    }

    setDisabledSmsSend(true);
    setCount(THE_COUNT_NUM - 1);
    setSmsSendButtonText(`${count}s`);

    const params = {
      mobile: ['234', mobile].join(''),
      clientId,
    };

    httpClient
      .post(api.SEND_SMS_CODE, params)
      .then((response: any) => {
        setHasSendSmsCode(true);

        if (response.code !== '200') {
          if (response.code === '500000098') {
            Alert.error(response.msg || 'The account has been closed and cannot be accessed.');
          } else {
            Alert.error(response.msg || 'Verification code failed to send, please try again.');
          }

          setCount(THE_COUNT_NUM);
          setSmsSendButtonText('Resend verification code');
          setDisabledSmsSend(false);

          return;
        }
        Alert.success('Verification code sent successfully');
      })
      .catch(() => {});
  };

  const applyDeleteInfo = (mid: string, custId = '', phone: string) => {
    var params = {
      mid,
      custId,
      phone,
      clientId,
    };
    httpClient
      .post(api.APPLY_DELETE_INFO, params)
      .then((response: any) => {
        // console.log(response);
        if (response.code === '0' && response.data) {
          // location.href = './doing.html?phone=' + params.phone;
          navigate({ pathname: '/delete-account/doing', search: `?phone=${params.phone}` });
        } else if (response.code === '500000099') {
          Alert.error(response.msg || 'Error, return to the update page.');
        } else {
          Alert.error(response.msg || 'Sending error');
        }
      })
      .catch(() => {})
      .finally(() => {
        setDisabledConfirm(false);
      });
  };

  const loginUser = () => {
    const params = {
      type: 0,
      mobile: ['234', mobile].join(''),
      code: smsCode,
      clientId,
    };

    httpClient
      .post(api.LOGIN, params)
      .then((response: any) => {
        // console.log(response)
        if (response === '' || response === null) {
          // location.href = './done.html';
          navigate('/delete-account/done');
        } else if (response.result && response.result.mid) {
          checkStatus({
            phone: params.mobile,
            mid: response.result.mid,
            custId: response.result.custId,
            clientId,
          });
        } else {
          response.msg && Alert.error(response.msg || 'Sending error');
          setDisabledConfirm(false);
        }
      })
      .catch(() => {
        setDisabledConfirm(false);
      });
  };

  const checkStatus = (params: { phone: string; mid?: string; custId?: string; clientId?: string }) => {
    // navigate('/delete-account/doing');
    // return ;

    setDisabledConfirm(true);

    httpClient
      .post(api.CHECK_STATUS, params)
      .then((response: any) => {
        // console.log(response);
        if (response.code !== '0') {
          // error
          Alert.error(response.msg || 'Sending error');
          setDisabledConfirm(false);
          return false;
        }

        if (parseInt(response.data.isInterrupt) === 1) {
          // Interrupted -> Has history deletion apply within 30 days
          // location.href = './error-again.html?applyTime=' + data.applyTime;
          navigate({ pathname: '/delete-account/error-again', search: `?applyTime=${response.data.applyTime}` });
        } else if (parseInt(response.data.admission) === 1) {
          // Admission -> There are unfinished orders
          // location.href = './error-order.html';
          navigate('/delete-account/error-order');
        } else if (parseInt(response.data.status) === 1) {
          // deleted
          // location.href = './done.html';
          navigate('/delete-account/done');
        } else if (parseInt(response.data.status) === 0) {
          // wait for deletion, doing page
          // location.href = './doing.html?phone=' + params.phone;
          navigate({ pathname: '/delete-account/doing', search: `?phone=${params.phone}` });
        } else {
          if (params.mid) {
            // after logging in
            applyDeleteInfo(params.mid, params.custId, params.phone);
            return;
          }

          loginUser();
        }
      })
      .catch(() => {
        setDisabledConfirm(false);
      });
  };

  const onConfirm = () => {
    // console.log('confirm');
    if (!validateForm()) {
      return false;
    }

    const params = {
      phone: ['234', mobile].join(''),
      clientId,
    };

    checkStatus(params);
  };

  const [disabledConfirm, setDisabledConfirm] = React.useState(false);

  React.useEffect(() => {
    // console.log([mobile, vCodeInputValue, smsCode, checked].join(', '));
    setDisabledConfirm(mobile && vCodeInputValue && smsCode && checked ? false : true);
  }, [mobile, vCodeInputValue, smsCode, checked]);

  return (
    <FormContainer>
      <h4 className="request-form-title">Request to Delete Your Account</h4>
      <div className="request-form-group">
        <span className="phone-country-code">+234</span>
        <input
          type="text"
          placeholder="Your phone number"
          pattern="[0-9]*"
          inputMode="numeric"
          maxLength={10}
          value={mobile}
          onChange={(e) => setMobile(e.currentTarget.value)}
        />
      </div>
      <div className="request-form-group form-group-vcode">
        <input
          type="text"
          placeholder="The graphic verification code"
          maxLength={4}
          onInput={(e) => setVCodeInputValue(e.currentTarget.value)}
        />
        <span id="vCodeButton">
          <VCode width={160} height={66} length={4} onChange={(value) => setVCodeValue(value)} />
        </span>
      </div>
      <div className="request-form-group">
        <input
          type="text"
          placeholder="The SMS verification code"
          pattern="[0-9]*"
          inputMode="numeric"
          maxLength={6}
          value={smsCode}
          onChange={(e) => setSmsCode(e.currentTarget.value)}
        />
      </div>
      <div className="request-form-send-verification-code">
        <button disabled={disabledSmsSend} onClick={onVCodeSend}>
          {smsSendButtonText}
        </button>
      </div>
      <div className="request-form-checkbox">
        <input type="checkbox" name="checked" id="checked" onChange={(e) => setChecked(e.target.checked)} />
        <label htmlFor="checked">I have read the prompts and agree to delete the account!</label>
      </div>
      <div className="request-form-submit">
        <FormButton onClick={onConfirm} disabled={disabledConfirm}>
          Request for Account Deletion
        </FormButton>
      </div>
    </FormContainer>
  );
};

const Application: React.FC = () => {
  return (
    <Layout>
      <Reminder />
      <RequestForm />
    </Layout>
  );
};

export default Application;
