import React from 'react';
import './Layout.css';
import styled from 'styled-components';
import Path1 from '../../../assets/images/Path_4.png';
import Path2 from '../../../assets/images/Path_5.png';
import { mediaQueries } from '../../../mediaQueries';

const Shape2 = styled.div`
    position: absolute;
    right: 0;
    top: 50px;
    z-index: 0;
    width: 440px;
    height: 750px;
    background-image: url(${Path2});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: none;
  ${() => mediaQueries('md')('display: block')}
`;

const Shape1 = styled.div`
    position: absolute;
    left: 0;
    top: 70px;
    z-index: 0;
    width: 674px;
    height: 585px;
    background-image: url(${Path1});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: none;
  ${() => mediaQueries('md')('display: block')}
`;

interface LayoutProps {
  children?: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = (props) => {
  return (
    <div className="Layout">
      <div className="Layout-content container flex">{props.children}</div>
      <Shape1 />
      <Shape2 />
    </div>
  );
};

export default Layout;
