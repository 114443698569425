import Product from './Product/Product';
import ContactUs from './ContactUs/ContactUs';
import AboutUs from './AboutUs/AboutUs';
import React from 'react';

const Home: React.FC = () => {
  return (
    <>
      <AboutUs />
      <Product />
      <ContactUs />
    </>
  );
};
export default Home;
