import React from 'react';
import './AboutUs.css';
import Cellphone from '../../../assets/images/White_Matte.png';
import GooglePlayIcon from '../../../assets/svg/googleplay.svg';
import BottomPadding from '../../../assets/images/Rectangle.png';
import config from '../../../config';

const AboutUs: React.FC = () => {
  return (
    <section id="Home" className="aboutus flex flex-column">
      <div className="container flex relative aboutus__container">
        <div className="aboutus__bg"></div>
        <div className="aboutus__image">
          <img className="aboutus__img" src={Cellphone} alt="" />
        </div>
        <div className="aboutus__content flex flex-column justify-center">
          <h2 className="aboutus__title">Apply for an Online Loan Through Cashpaddie</h2>
          <p>
            We help Nigerian find a solution for their individual financial needs. <br />
            Providing quality service and financial resources to Nigerian is what we do.
          </p>
          <div className="aboutus__download">
            <a className="aboutus__download-googleplay" href={config.googleplay}>
              <span>Google Play</span>
              <img src={GooglePlayIcon} alt="" />
            </a>
            <a className="aboutus__download-android" href={config.apk}>
              <span>Download</span>
              <i className="ri-arrow-down-circle-line"></i>
            </a>
          </div>
        </div>
      </div>

      <img src={BottomPadding} alt="" />
    </section>
  );
};

export default AboutUs;
