import React from 'react';
import './AgreementWrap.css';
import styled from 'styled-components';
import Path1 from '../../assets/images/Path_4.png';
import Path2 from '../../assets/images/Path_5.png';
import Path3 from '../../assets/images/Path_6.png';

const Shape2 = styled.div`
  position: absolute;
  right: 0;
  bottom: 300px;
  z-index: 0;
  width: 24%;
  height: 1050px;
  background-image: url(${Path2});
  background-repeat: no-repeat;
  background-size: 100% 100%;
`;

const Shape3 = styled.div`
  position: absolute;
  left: 0;
  bottom: 200px;
  z-index: 0;
  width: 240px;
  height: 958px;
  background-image: url(${Path3});
  background-repeat: no-repeat;
  background-size: 100% 100%;
`;

const Shape1 = styled.div`
  position: absolute;
  left: 0;
  top: 70px;
  z-index: 0;
  width: 674px;
  height: 585px;
  background-image: url(${Path1});
  background-repeat: no-repeat;
  background-size: 100% 100%;
`;

interface AgreementWrapProps {
  children?: React.ReactNode;
}

const AgreementWrap: React.FC<AgreementWrapProps> = (props) => {
  return (
    <div className="agreement">
      <div className="agreement-content">{props.children}</div>
      <Shape1 />
      <Shape2 />
      <Shape3 />
    </div>
  );
};

export default AgreementWrap;
