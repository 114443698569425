import React, { CSSProperties,useContext } from 'react';
import './ContactUs.css';
import SectionTitle from '../../SectionTitle';
import TheImage from '../../../assets/images/Slice_5.png';
import IconEmail from '../../../assets/images/Icon_email.png';
import IconPhone from '../../../assets/images/Icon_phone.png';
import IconWhatsapp from '../../../assets/images/Icon_whatsapp.png';
import DataContext from '../../../DataContext';
interface IconProperties extends CSSProperties {
  element: string;
}

interface Item {
  title: string;
  icon: IconProperties;
  data: string;
  type: string;
}

interface ContactItemProps extends Item {}

const ContactItem = ({ title, data, icon }: ContactItemProps) => {
  return (
    <li className="contact-item">
      <div className="contact-item__img" style={{ background: icon.background }}>
        <img src={icon.element} style={{ width: icon.width + '%', height: icon.height + '%' }} alt="contact us" />
      </div>
      <div className="contact-item__content flex flex-column justify-center">
        <div className="contact-item__title">{title}</div>
        <div className="contact-item__data">{data}</div>
      </div>
    </li>
  );
};

const ContactList = () => {
  const itemsData: Item[] = [
    {
      title: 'Email',
      type:'email',
      icon: {
        background: '#FEF7E8',
        width: 45.5,
        height: 38.5,
        element: IconEmail,
      },
      data: '',
    },
    {
      title: 'Phone',
      type:'dial',
      icon: {
        background: '#EEF8FF',
        width: 37.7,
        height: 58.5,
        element: IconPhone,
      },
      data: '',
    },
    {
      title: 'Whatsapp',
      type:'whatsapp',
      icon: {
        background: '#EDFDF6',
        width: 48.1,
        height: 48.1,
        element: IconWhatsapp,
      },
      data: '',
    },
  ];
  const contextValue:any = useContext(DataContext)
  if(contextValue.data && contextValue.data.length > 0){
    contextValue.data.forEach((element:any) => {
      itemsData.forEach((item) => {
        if(element.type.toLowerCase() === item.type.toLowerCase()){
          item.data = element.value;
        }
      })
    })
  }
  return (
    <ul className="contact-list">
      {itemsData.map((data, i) => (
        <ContactItem {...data} key={i} />
      ))}
    </ul>
  );
};

const ContactUs: React.FC = () => {
  return (
    <section id="Contact" className="contact-section container">
      <SectionTitle title="Contact Us" />
      <div className="contact-content flex justify-between">
        <ContactList />
        <img className="contact-img" src={TheImage} alt="contact with us" />
      </div>
    </section>
  );
};

export default ContactUs;
