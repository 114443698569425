import React from 'react';

interface Data {
  data: any | null;
}

const DataContext = React.createContext<Data>({
    data: null
  });

export default DataContext;