import React from 'react';
import Reminder from '../components/Reminder/Reminder';
import Layout from '../components/Layout/Layout';
import FormContainer from '../components/FormContainer';
import IconSuccess from '../../assets/images/delete-account/Icon_success.png';
import './Cancel.css';
import { useNavigate } from 'react-router-dom';

const ErrorAgain: React.FC = () => {
  const navigate = useNavigate();
  const THE_COUNT_NUMBER = 5;
  const [count, setCount] = React.useState(THE_COUNT_NUMBER);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setCount(count - 1);
    }, 1000);
    if (count === 0) {
      // window.location.href = '/';
      navigate('/delete-account');
    }
    return () => clearTimeout(timer);
  }, [count, navigate]);
  return (
    <FormContainer>
      <div className="cancel">
        <img className="cancel-icon" src={IconSuccess} alt="" />
        <div className="cancel-content">
          <p>The account deletion has been terminated and you will be automatically redirected to the application page in 5 seconds.</p>
        </div>
      </div>
    </FormContainer>
  );
};

const Application: React.FC = () => {
  return (
    <Layout>
      <Reminder />
      <ErrorAgain />
    </Layout>
  );
};

export default Application;
