import React from 'react';
import Reminder from '../components/Reminder/Reminder';
import Layout from '../components/Layout/Layout';
import FormContainer from '../components/FormContainer';
import FormButton from '../components/FormButton';
import IconError from '../../assets/images/delete-account/Icon_error.png';
import './ErrorOrder.css';

const ErrorAgain: React.FC = () => {
  return (
    <FormContainer>
      <div className="error-order">
        <img className="error-icon" src={IconError} alt="" />
        <div className="error-content">
          <p>You currently have pending orders. Please complete them before applying to delete your account.</p>
        </div>
        <FormButton onClick={() => window.history.back()}>Return</FormButton>
      </div>
    </FormContainer>
  );
};

const Application: React.FC = () => {
  return (
    <Layout>
      <Reminder />
      <ErrorAgain />
    </Layout>
  );
};

export default Application;
