import React from 'react';
import Reminder from '../components/Reminder/Reminder';
import Layout from '../components/Layout/Layout';
import FormContainer from '../components/FormContainer';
import IconWarningFill from '../../assets/images/delete-account/Icon_warning_fill.png';
import IconWarning from '../../assets/images/delete-account/Icon_warning.png';
import FormButton from '../components/FormButton';
import './Doing.css';
import httpClient from '../../http-client';
import config from '../../config'
import { useSearchParams } from 'react-router-dom';
import { Alert } from '../../alert';
import { formatTimestampToMexDate } from '../../date-format';
import { createEnv } from '../../server-config';

const ErrorAgain: React.FC = () => {
  const clientId = createEnv().clientId;
  const api = config.api.deleteAccount;
  const [searchParams] = useSearchParams();
  const [deleteTime, setDeleteTime] = React.useState('')
  const [mid, setMid] = React.useState('')
  const [custId, setCustId] = React.useState('')

  React.useEffect(() => {
    const params = {
      phone: searchParams.get('phone'),
      clientId,
    };
    httpClient.post(api.CHECK_STATUS, params).then((response: any) => {
      if (response.code !== '0') {
        // error
        Alert.error(response.msg || 'Sending error');
        return;
      }

      setMid(response.data.mid);
      setCustId(response.data.custId);
      setDeleteTime(formatTimestampToMexDate(response.data.delTime));
    });
  }, [api.CHECK_STATUS, searchParams, clientId]);


  const cancelDelection = () => {
    Alert.confirm('You can only apply for account deletion once within a month.').then((action) => {
      // console.log(action);
      if (action.isConfirmed) {
        const params = {
          phone: searchParams.get('phone'),
          mid,
          custId,
          clientId,
        };
        httpClient.post(api.TERMINATION_DELETE, params).then((response: any) => {
          if (response.code !== '0') {
            // error
            Alert.error(response.msg || 'Sending error');
            return;
          }
          window.location.href = '/delete-account/cancel';
        });
      }
    });
   
  }

  return (
    <FormContainer>
      <div className="doing">
        <img className="doing-icon" src={IconWarning} alt="" />
        <div className="doing-content">
          <p>
            <span className='doing-delete-your-warnings'>Your account will be deleted on </span><span className="doing-delete-time">{deleteTime}</span> To withdraw the deletion
            request, please click "Cancel Account Deletion" below.
          </p>
        </div>
        <FormButton onClick={cancelDelection}>Cancel Account Deletion</FormButton>
        <div className="doing-message">
          <img src={IconWarningFill} alt="" />
          <span>You can only apply for account deletion once within a month.</span>
        </div>
      </div>
    </FormContainer>
  );
};

const Application: React.FC = () => {
  return (
    <Layout>
      <Reminder />
      <ErrorAgain />
    </Layout>
  );
};

export default Application;
