import React from 'react'

export const useNumberState = (defaultVal = ''): [string, (val: string) => void] => {
  const removeLeadingZeros = (s: string) => {
    const oldLen = s.length;
    // s = s.replace(/^0+/, '');
    if (s.length === 0 && oldLen > 0) {
      s = '0';
    }
    return s;
  };

  defaultVal = removeLeadingZeros(defaultVal);
  const [numVal, setNumVal] = React.useState(defaultVal);

  const handleChange = (val: string) => {
    val = val.replace(/[^\d]/g, '');
    val = removeLeadingZeros(val);
    setNumVal(val);
  };

  return [numVal, handleChange];
};

