import React from 'react';
import styled from 'styled-components';
import { mediaQueries } from '../../mediaQueries';

const Container = styled.div`
  ${() =>
    mediaQueries('lg')(`
      flex: 0 0 32.5%;
      margin-left: 4.5%;
      margin-top: 50px;
      margin-bottom: 50px;
      padding: 0 52px 60px;
      border: 2px solid var(--first-color-dark);
      border-radius: 20px;
      background: linear-gradient(180deg, #f1f9ff 0%, #ffffff 100%);
  `)};
`;

interface FormContainerProps {
  children?: React.ReactNode;
}

const FormContainer: React.FC<FormContainerProps> = (props) => {
  return <Container>{props.children}</Container>;
};

export default FormContainer;
