import React from 'react';
import Reminder from '../components/Reminder/Reminder';
import Layout from '../components/Layout/Layout';
import FormContainer from '../components/FormContainer';
import IconSuccess from '../../assets/images/delete-account/Icon_success.png';
import './Done.css';

const ErrorAgain: React.FC = () => {
  return (
    <FormContainer>
      <div className="done">
        <img className="done-icon" src={IconSuccess} alt="" />
        <div className="done-content">
          <p>Your account has been deleted.</p>
        </div>
      </div>
    </FormContainer>
  );
};

const Application: React.FC = () => {
  return (
    <Layout>
      <Reminder />
      <ErrorAgain />
    </Layout>
  );
};

export default Application;
