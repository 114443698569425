class EnvConfig {
  type: string;
  endpoint: string;
  clientId: string;

  constructor(type = '', endpoint = '', clientId = '') {
    this.type = type;
    this.endpoint = endpoint;
    this.clientId = clientId;
  }
}

export function createEnv() {
  var env: Record<string, EnvConfig> = {
    localhost: new EnvConfig('develop', 'http://loansapp-giasun-web-hwt1.jirongyunke.net', '71ee88bf-3ff4-46dd-aa02-baded7a4bfe6'),
    'h5-hwt1.cashpaddie.com': new EnvConfig('test', 'http://loansapp-giasun-web-hwt1.jirongyunke.net', '71ee88bf-3ff4-46dd-aa02-baded7a4bfe6'),
    production: new EnvConfig('production', 'https://loansapp.cashpaddie.com', 'f9104f2c-e8f0-45df-a69c-571ba38c1056'),
  };

  var json = env[window.location.hostname] || env['production'];
  // console.log('[Env]', json.type);
  return json;
}
