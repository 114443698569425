import React from 'react';
import { useSearchParams } from 'react-router-dom';

import Reminder from '../components/Reminder/Reminder';
import Layout from '../components/Layout/Layout'
import FormContainer from '../components/FormContainer';
import FormButton from '../components/FormButton';
import IconError from '../../assets/images/delete-account/Icon_error.png';
import './ErrorAgain.css';
import { formatTimestampToMexDate } from '../../date-format';

const ErrorAgain: React.FC= () => {
  const [applyTime, setApplyTime] = React.useState('');
  const [searchParams] = useSearchParams();

  React.useEffect(() => {
    const _applyTime = searchParams.get('applyTime');
    if (_applyTime) {
      setApplyTime(formatTimestampToMexDate(Number(_applyTime)));
    }
  }, [searchParams]);

  return (
    <FormContainer>
      <div className="error-again">
        <img className="error-icon" src={IconError} alt="" />
        <div className="error-content">
          <p>You can only apply once within 30 days. You have already applied once on {applyTime}</p>
          <p>Please come back to apply after 30 days.</p>
        </div>
        <FormButton onClick={() => window.history.back()}>Return</FormButton>
      </div>
    </FormContainer>
  );
}

const Application: React.FC = () => {
  return <Layout>
    <Reminder />
    <ErrorAgain />
  </Layout>
};

export default Application;
