import React, { useContext } from 'react';
import AgreementWrap from './AgreementWrap';
import AgreementTitle from './AgreementTitle';
import DataContext from '../../DataContext';
const TermsOfUse: React.FC = () => {
  const contextValue:any = useContext(DataContext)
  let email:any
  if(contextValue.data && contextValue.data.length > 0){
    contextValue.data.forEach((element:any) => {
      if(element.type === "email"){
        email = element.value;
      }
    });
  }
  return (
    <AgreementWrap>
      <div className="container">
        <AgreementTitle title="Terms and Conditions" />
        <p>
          This Terms of Use (or “Agreement” where context so requires), is the legal agreement, entered into between you (hereinafter
          referred to as "user" where context so requires) and <strong>Cashpaddie</strong> (hereinafter referred to as "we", "us", or
          "our"), setting down the terms and conditions for your use of our mobile application [Cashpaddie] ("App") and or our website
          [http://cashpaddie.com](the “Website”), in order to enjoy the products and services (‘products’, ‘services’, ‘products and
          services’) we provide through the Mobile App or Website. This Agreement is consequential upon your account opening with us.
        </p>
        <h3>1. Acceptance of this Agreement</h3>
        <p>
          <strong>
            PLEASE carefully read this Term of use before using our Service. If you do not agree to be bound with these terms and
            conditions, please do not proceed to access or use our Service.
          </strong>
        </p>
        <h3>2. Accessing Our Service</h3>
        <p>
          You may not use our Service, if <br />
          <ul>
            <li>
              <p>a. you do not accept and agree to Terms of Use and the Privacy Policy which can be herein accessed；</p>{' '}
            </li>
            <li>
              <p>b. you are not of legal age to enter into this agreement with us;</p>
            </li>
            <li>
              <p>c. you are not a Nigerian Resident;</p>
            </li>
            <li>
              <p>d. you are prohibited by law from using our Service.</p>
            </li>
          </ul>
        </p>
        <h3>3. Creating the Account</h3>
        <p>
          3.1 Our service permits you to create your account in our App, which requires you to provide necessary information we require for
          same, to wit; your name, email address, contact address, mobile number, your bank account particulars (‘User information’). Do
          ensure that the user information is accurate as false User Information or identity theft constitutes a breach of this Agreement,
          which will result in termination of your access to our Service and your account with us will be consequently closed.
        </p>
        <p>
          3.2 Every user must only have one account with our App. To create an account, you must provide the User Information. You hereby
          represent that you have the capacities and power necessary to create the account in the Platform, either under the laws and
          regulations or under any contractual obligations binding on you, and you shall indemnify us against any losses, claims, and
          actions in relation to the creation of the account in the Platform which is not in line with the representations and warranties
          that you provide.
        </p>
        <p>
          3.3 All users are enjoined to keep their account and password private. You also agree to immediately notify us of any unauthorized
          use of your password or account or any other breach of security. In the event that you lose or discloses the aforementioned
          account and/or equipment, the user shall promptly notify us in order to mitigate any possible damage. Regardless that complaints
          have been lodged, the user is solely responsible for any loss or damage caused by mismanagement of his account information or
          equipment.
        </p>
        <p>
          3.4 Users are obliged to maintain and update their registered user profile to ensure that it is true, accurate, valid and
          complete. If we are unable to provide the Services or make any errors in the provision of the Services due to their failure to
          keep their user information up to date, they may not use this as ground for canceling the transaction or refusing payment and they
          shall be personally liable for all emanating consequences.
        </p>
        <h3>4. USING THE ACCOUNT </h3>
        <p>
          4.1 In order to offer you our service, we have the right to check the credit status and records through credit references and
          fraud prevention agencies for any purpose permitted by applicable law, including but not limited to authenticate your identity;
          (ii) to make loan decisions; (iii) to service the your loan .If you do not cooperate with such inquiries or we cannot verify your
          identity, or your credit status does not meet our service requirements, we can refuse to allow you to use the Mobile App and/or
          the Services.
        </p>
        <p>
          4.2 When using the account, the user shall:
          <ul>
            <li>
              <p>i) not infringe any intellectual property or other proprietary rights of third parties; </p>
            </li>
            <li>
              <p>ii) not forge false identities or publish false information </p>
            </li>
            <li>
              <p>iii) not destroy, interfere with, intercept or usurp any system, data or personal data; </p>
            </li>
            <li>
              <p>
                iv) not publish information prohibited by national laws and regulations, including but not limited to any form of
                information that is unlawful, harmful, threatening, abusive, harassing, tortious, violent, defamatory, pornographic,
                libelous, hateful racially, or ethnically; and{' '}
              </p>
            </li>
            <li>
              <p>v) not use this account to foment criminal activity or seek to provoke others to criminal act; </p>
            </li>
            <li>
              <p>vi) not to have any other behavior that violates this Terms of Use. </p>{' '}
            </li>
          </ul>
        </p>
        <p>
          4.3 When using our loan service, all users shall warrant that the loan amount you obtain from us will solely for the purpose of
          personal consumption, you shall not use the loan service to foment criminal activity such as money laundry or seek to provoke
          others to criminal act.
        </p>
        <p>
          4.4 We shall have the rights to close your account if you do not comply with the Terms of Use. We reserve the right to investigate
          and take appropriate legal actions including but not limited to suspending or terminating your account, claiming for compensation,
          and reporting you to law enforcement authorities. You shall you indemnify us against any losses, claims in the event of your
          violation of this Terms of Use.
        </p>
        <h3>5. CANCELLATION OF THE ACCOUNT</h3>
        <p>
          Every user have the right to request for the cancellation of his account with us where such user does not intend to continue
          operating the account . Provided that, the user shall pay off all amounts due, including but not limited to the loan amount,
          interest, late payment interest, service fees. Upon cancellation of the account, this Agreement shall be terminated, and we are
          not obliged to retain or disclose any information in the user account for or to you.
        </p>
        <h3>6. THIRD PARTY WEBSITE</h3>
        <p>
          6.1 The content of our App or Website may refer to other websites owned, controlled or operated by third party ("Third Party
          Website") and contain links to third party websites.
        </p>
        <p>
          6.2 We do not guarantee the authenticity and validity of the information on third party websites. Users acknowledge that they are
          using the Third-Party Website in accordance with the relevant terms of use of its website and not in accordance with this
          Agreement.
        </p>
        <p>
          6.3 If you visit a Third-Party Website, it is important to be aware of the privacy policy of the Third-Party Website and we shall
          not be responsible for the privacy protection of all Third Party Websites.
        </p>
        <h3>7. AMENDMENT OF THE TERMS OF USE</h3>
        <p>
          We reserve the right to amend this Terms of Use at any time without advance notice unless required by law, including when there
          are changes in our Services, technology, laws, or for other reasons. All changes will be effective upon publication and apply to
          all users, you are enjoined therefore, to check this Agreement periodically. Your continued use of Our App, Website or Services
          after any change to this Agreement constitutes your agreement to be bound by any such changes. We also reserve the right to
          terminate, suspend or change the Services, or any part of the Services, without notice or liability. If you do not agree to any
          change in this Agreement, you should discontinue using our Services.
        </p>
        <h3>8. INTELLECTUAL PROPERTY</h3>
        <p>
          8.1 Our App and Website may contain intellectual property including industrial property, copyrights, proprietary information,
          technology, product, software, program, application, data processing methods, computer codes, graphics, designs, trademarks in any
          form, which are owned by us or authorized to us.
        </p>
        <p>
          8.2 Nothing in this Agreement shall be considered as the granting of the right to use our intellectual property by implication,
          any license or right to use any of our intellectual property displayed on our App or Website without our prior written
          confirmation. You may not modify, distribute, prepare derivative works of, reverse engineer, reverse assemble, disassemble,
          decompile or otherwise attempt to decipher the App and Website and the technology provided by us
        </p>
        <p>
          8.3 You shall not have claims over any intellectual properties for any contents you upload through the App or the Website, and
          therefore, all contents you upload shall be used, copied, distributed, stored, by the us and the we shall be entitled to obtain
          economic benefit for the use of the contents, including but not limited to using, copying, communicating or in any manner
          whatsoever obtaining economic benefits from exercising the intellectual property rights. This provision is subject to Privacy
          Policy which can be accessed here.
        </p>
        <h3>9. NO WARRANTY</h3>
        <p>
          9.1 In the event that the system is not functioning properly or the use of the service is in any way affected, we will endeavor to
          restore access to the service but we will not be liable to the user or to third parties for but not limited to the following
          conditions :
          <ul>
            <li>
              <p>i) during system downtime for maintenance; failure of telecommunications equipment; </p>
            </li>
            <li>
              {' '}
              <p>
                ii) interruptions or delays in the service due to hacker attacks, technical adjustments or failures of network providers,
                website upgrades, network problems of banks.{' '}
              </p>{' '}
            </li>
          </ul>
        </p>
        <p>
          9.2 We do not give any warranty, whether express or implied to any user and/or any transaction. The users should make their own
          decisions based on their own judgement. The use of our services is at the user's own risk and the user is not entitled to make any
          legal claims against us. Disputes arising between the parties in related to a transaction during the course of the service shall
          be resolved by the parties themselves.
        </p>
        <h3>10. LIMITATION OF LIABILITY</h3>
        <p>
          10.1 You agree to release us and our personnel from any claims, liabilities, costs and expenses relating to our services in this
          engagement except to the extent determined to have resulted from intentional and deliberate misconduct of us or our personnel. You
          also understand that we shall not be responsible for losses suffered by any third party from our services, and the loss shall
          fully be the responsibility of the third party.
        </p>
        <p>
          10.2 You agree to also release us and our personnel from any claims, liabilities, costs and expenses relating to our services
          attributable to any misrepresentations made by you.
        </p>
        <p>10.3 We shall not be liable to you for any consequential or indirect or punitive loss and damage.</p>
        <h3>11. MISCELLANEOUS PROVISIONS</h3>
        <p>
          11.1 This Agreement and any non-contractual obligations connected with it shall be governed by and construed in accordance with
          Nigerian law. Any dispute, difference, controversy, or claim arising out of or in connection with this Agreement shall be referred
          to and finally resolved by court in Lagos, Nigeria.
        </p>
        <p>
          11.2 In the event any provision of this contract shall be invalid, illegal or unenforceable in any respect, such a provision shall
          be considered separate and severable from the remaining provisions of this contract, and the validity, legality or enforceability
          of any of the remaining provisions of this contract shall not be affected or impaired by such provision in anyway.
        </p>
        <p>
          11.3 No failure or delay by us in exercising any right, power or privilege hereunder shall operate as a waiver thereof, nor shall
          any single or partial exercise thereof preclude any other or further exercise thereof or the exercise of any right, power or
          privilege hereunder.
        </p>
        <p>
          11.4 This Terms of Use is dated 2022. We reserve the right to change the Terms of Use from time to time, please check this
          Agreement periodically.
        </p>
        <p>
          11.5 The headings of each paragraph, which were inserted for reference purposes only, shall be disregarded in the interpretation
          of the paragraph.
        </p>
        <h3> 12. COMPLAINTS AND INFORMATION SERVICES</h3>
        <p>
          If you have any questions on our service and products, any complains or suggestions, you may contact us in our App or by email at
          [{email}].
        </p>
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </AgreementWrap>
  );
};

export default TermsOfUse;
