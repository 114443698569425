import Swal from 'sweetalert2';

export class Alert {
  public static error(message = '') {
    return Swal.fire({
      title: 'Error!',
      text: message || 'Something went wrong',
      confirmButtonText: 'OK',
      confirmButtonColor: 'var(--first-color-dark)',
    });
  }

  public static success(message = '') {
    return Swal.fire({
      position: 'center',
      icon: 'success',
      text: message || 'Done!',
      showConfirmButton: false,
      timer: 1500,
    });
  }

  public static confirm(message = '') {
    return Swal.fire({
      text: message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'var(--first-color-dark)',
      cancelButtonColor: 'var(--text-color-light)',
      confirmButtonText: 'Confirm',
      reverseButtons: true,
      customClass: {
        icon: 'swal2-warning--custom',
      },
    });
  }
}
