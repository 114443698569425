import React, { useContext } from 'react';
import AgreementTitle from './AgreementTitle';
import AgreementWrap from './AgreementWrap';
import './PrivacyPolicy.css';
import DataContext from '../../DataContext';
const PrivacyPolicy: React.FC = () => {
  const contextValue:any = useContext(DataContext)
  let email:any
  let phone:any
  if(contextValue.data && contextValue.data.length > 0){
    contextValue.data.forEach((element:any) => {
      if(element.type === "email"){
        email = element.value;
      }
      if(element.type === "dial"){
        phone = element.value;
      }
    });
  }
  return (
    <AgreementWrap>
      <div className="container">
        <AgreementTitle title="Privacy Policy" />
        <p>
          This Privacy Policy was prepared to protect and maintain the privacy of Product Users. The Product was created, owned and operated
          by QUARK FINANCIALS NIGERIA LIMITED (“QUARK”, “Cashpaddie”, “we”, “us”, or “our”). This Privacy Policy does not apply to any
          websites or mobile applications which are not operated by us that You may access via the Product. Users are requested to read and
          understand this Privacy Policy carefully before using the Service. By i) clicking or checking or otherwise indicating the User's
          intent to accept or agree to this Privacy Policy, and/or ii) accessing and using the Services on the Product, the User
          acknowledges that he has read, understood and agreed to every provision written in this Privacy Policy and the terms of use of the
          Product. The Privacy Policy also stipulates the actions in which Cashpaddie obtains, collects, processes, analyses, stores,
          rectifies and updates, displays, announces, transfers, disseminates, discloses, deletes, destroys personal information. By
          visiting or using the Services on the Product the User is deemed to have read, and agrees to the acquisition, processing and
          analysis, storage, rectification, updates, display, announcement, transfer, dissemination, disclosure, deletion, and destruction
          of User's Information in accordance with the Privacy Policy. This Product is designed and intended for use by persons residing in
          Nigeria. If You are accessing this Product from outside Nigeria, please first confirm that the laws in Your country of residence
          permit the use of this Product. If the use of the Product is prohibited in Your country of residence, We strongly advise that You
          do not access or use the Product.
        </p>
        <ol>
          <li className="paragraph-title">
            <strong>1. General Provisions</strong>
          </li>
        </ol>
        <p>
          Cashpaddie shall collect from any user and to what degree, extent we may use, process and transfer the information when you
          register, access or use our Mobile App (Cashpaddie), Website (https://www.cashpaddie.com), products, and services (collectively,
          the “Services”). The Service provides users with the opportunity to apply for and be granted small loans subject to the terms and
          conditions agreed upon by both parties. By visiting and/or using our Service, you are accepting and consenting to the practices
          described in this Privacy Policy.
        </p>
        <ol>
          <li className="paragraph-title">
            <strong>2. Definitions</strong>
          </li>
        </ol>
        <p>
          In addition to those specified elsewhere in this Privacy Policy, the following terms will have the definitions, meanings and
          interpretations specified below:
        </p>
        <ol>
          <li>
            2.1 "<strong>Personal Information/Data</strong>" means shall include all data, information and documents, whether electronic or
            non-electronic, of or concerning the User and/or related parties of the User (including but not limited to the User's family,
            associates, employees, companies or service providers, if required for emergency contact or for other legitimate reasons) that
            Cashpaddie receives or accesses from the User or third parties, submitted, provided or disclosed by the User to Cashpaddie,
            according to the consent of the User concerned and stored and managed in connection with the provision of Services on the
            Product and in the context of the utilization of Services on the Product by the User.
          </li>
          <br />
          <li>
            2.2 "<strong>User</strong>" means any person registering Cashpaddie's Application and/or using services provided by us.
          </li>
          <br />
          <li>
            2.3 "<strong>Terms and Conditions</strong>" are the terms and conditions for using the Product set by the Provider, as
            accessible and have been understood and approved by Users when registering through the Product.
          </li>
          <br />
          <li>
            2.4 <strong>User’s Information</strong> means includes i) User’s “Personal Information/Data” and ii) other information/data
            mentioned in this Privacy Policy.
          </li>
          <br />
          <li>
            2.5 "<strong>Law</strong>" means all laws and regulations and their implementing regulations, decrees or policies that are in
            effect and have legal force including regulations, regional regulations and other derivative laws, requirements and guidelines
            in the Federal Republic of Nigeria
          </li>
          <br />
          <li>
            2.6 "<strong>Cookies</strong>" means a type of file containing information sent by a website/application to the computer hard
            disk of every visitor who visits the website/application for the purpose of recording certain information by the computer, such
            as internet protocol (IP) addresses, browser type and operating system (OS), day, date and time when visitors access the website
            and data from small-click server streams to monitor website/application access activities and website/application addresses
            accessed by Users. Cookies function to remember important information that will help in using the website/application, if the
            User decides to visit the website/application again in the future.
          </li>
          <br />
          <li>
            2.7 "<strong>You</strong>", with grammatical variations and cognate expressions, refers to any other person using the Product
            and/or the Services whether or not registered on the Product.
          </li>
          <br />
          <li>
            2.8 “<strong>Process/Processing</strong>” means the actions includes:
          </li>
          <br />
          <li>a. acquisition and collection;</li>
          <br />
          <li>b. processing and analysis;</li>
          <br />
          <li>c. storage; rectification and updates;</li>
          <br />
          <li>d. appearance, announcement, transfer, distribution or disclosure; and/or</li>
          <br />
          <li>e. deletion or destruction.</li>
          <br />
          <li>
            <strong className="paragraph-title">3. Applicability and Consent</strong>
            <br />
            <br />
            <ol>
              <li>
                3.1 This Privacy Agreement is compiled and shall become an integral part of the terms and conditions for using Product
                (“Terms and Conditions”). Words or terms that use capital letters used in this Privacy Policy, unless otherwise specified in
                this Privacy Policy or if the context otherwise requires, will have the meaning as specified in the Terms and Conditions.
                Words in the singular are also in the plural and vice versa. By accessing the Product and/or providing Personal Information
                to Us, You have read and understood Our Privacy Policy and consent to the Processing of Your Personal Information in
                accordance with this Privacy Policy.
              </li>
              <br />
              <li>3.2 This Privacy Policy shall legally bind Users from the date:</li>
            </ol>
          </li>
          <br />
          <li>a. The User clicks or checks or otherwise indicates the User's intent to accept or agree to this Privacy Policy; and/or</li>
          <br />
          <li>b. Users access and use the Service and/or Product.</li>
          <br />
          <li>
            c. For avoidance of doubts, if this Privacy Policy is amended during Users’ accessing and using of the Service and/or Product,
            by continuing to access and use the Service and/or Product, Users agree to the amended Privacy Policy, and the amended Privacy
            Policy shall legally bind Users from Users’ first access and use of the Service and/or Product after the amendment.
            <ol>
              <br />
              <li>3.3 This Privacy Policy continues to apply as long as:</li>
            </ol>
            <br />
          </li>
          <li>a. The registered Users are registered as Users on the Product;</li>
          <br />
          <li>
            b. The registered Users still has obligations, financial responsibilities, debts or similar matters to the the Loan Agreement;
            and/or
          </li>
          <br />
          <li>
            c. The registered Users is still in the process of submitting an application for obtaining a loan along with an assessment of it
            by Cashpaddie or any third party cooperated by Cashpaddie.
          </li>
          <br />
          <li>
            d. The registered Users understand that we may share your Personal Information with the authorized government agencies and/or
            other institutions that may be appointed by government authorities. You agree and acknowledge that the retention of your
            Personal Information by the relevant government agencies and/or other institutions will follow the respective parties’ policies
            regarding data retention.
          </li>
          <br />
          <li>
            e. The registered Users understand that this Privacy Policy is still valid and binding to Users as long as is necessary for the
            purposes set out herein.
          </li>
          <br />
          <li>
            <strong className="paragraph-title">4. Legal Basis for Data Processing </strong>
          </li>
        </ol>
        <p>
          Pursuant to the applicable laws and regulations, We process your Personal Data based on one or more of the following legal
          grounds:
        </p>
        <ol>
          <li>4.1 Consent: processing based on your explicit consent for specific purposes.</li>
          <br />
          <li>
            4.2 Performance of a Contract: processing necessary to fulfil Users’ and our obligations under the agreement and other relevant
            documents.
          </li>
          <br />
          <li>4.3 Cashpaddie's Legal Obligation: processing necessary to comply with our legal obligations.</li>
          <br />
          <li>
            4.4 Legitimate Interests: processing necessary for our legitimate interests or those of third parties, provided your fundamental
            rights are not overridden.
          </li>
          <br />
          <li>4.5 Interest protection: processing based on the vital interest of personal data subject.</li>
        </ol>
        <ol>
          <li>
            <strong className="paragraph-title">5. Information We Collect</strong>
            <br />
            <br />
            <ol>
              <li>
                5.1 Collection of Personal Data as data controller shall be conducted by Cashpaddie or Cashpaddie's cooperative third
                parties from data subjects directly or indirectly after Cashpaddie owns the legal basis mentioned in Article 4 regarding
                Legal Basis for Data Processing and Cashpaddie shall collect in compliance to the applicable laws and regulations.
              </li>
              <br />
              <li>
                5.2 Collection of information of data subject (including but not limited to User) shall be conducted by Cashpaddie or
                Cashpaddie's cooperative third parties from data subjects directly or indirectly after Cashpaddie obtains prior consent
                unless otherwise required or exempted by applicable laws and regulations and Cashpaddie shall collect in compliance to the
                applicable laws and regulations.
              </li>
              <br />
              <li>5.3 The Personal Data collected shall include:</li>
              <br />
            </ol>
          </li>
          <li>
            a. Nigerian resident identity card number and other relevant information and/or other information made based on the User's
            Nigerian resident identity card, such as:
            <br />
            <br />
            <ol>
              <li>i. Name;</li>
              <br />
              <li>ii. Identification /passport number;</li>
              <br />
              <li>iii. religion;</li>
              <br />
              <li>iv. place and date of birth;</li>
              <br />
              <li>v. gender;</li>
              <br />
              <li>vi. marital status;</li>
              <br />
              <li>vii. number of offspring;</li>
              <br />
              <li>viii. educational status;</li>
              <br />
              <li>ix. contact number;</li>
              <br />
              <li>x. home status;</li>
              <br />
              <li>xi. period of stay in Nigeria;</li>
              <br />
              <li>xii. name of mother;</li>
              <br />
              <li>xiii. emergency contacts’ relationship with Users, name and phone number;</li>
              <br />
              <li>xiv. e-mail;</li>
              <br />
              <li>xv. Fax number;</li>
              <br />
              <li>xvi. tax identification number; and/or</li>
              <br />
              <li>xvii. alternative contact;</li>
            </ol>
          </li>
          <br />
          <li>
            b. submission of work information, such as:
            <br />
            <br />
            <ol>
              <li>i. company name;</li>
              <br />
              <li>ii. company address;</li>
              <br />
              <li>iii. company contact number;</li>
              <br />
              <li>iv. monthly income;</li>
              <br />
              <li>v. work period;</li>
              <br />
              <li>vi. occupation;</li>
              <br />
              <li>vii. job description;</li>
              <br />
              <li>viii. guarantor’s telephone number; and/or</li>
              <br />
              <li>ix. domicile;</li>
            </ol>
          </li>
          <br />
          <li>
            <strong>
              c. Camera information, Cashpaddie will use the camera to take photos(collect biometric data) and upload facial recognition
              images with your permission for biometric verification. Scuh photos/images will be uploaded to the server in encrypted form
              and will be processed in real time to confirm the User's identity and ensure that the subject of the selfie is the real User,
              thereby speeding up the loan application process. Please note that you have the right to allow or prohibit using your camera
              at any time by changing device settings.
            </strong>
          </li>
          <br />
          <li>
            <strong>
              d. SMS information. During the loan application process, we will collect and upload SMS log information with your consent. We
              collect SMS content from your inbox, including phone number, name, and SMS content. This information enables us to create your
              credit report, conduct financial risk assessment, and provide loan services and customized financial products. The collected
              SMS information will be encrypted and uploaded to the server. We will not share with third parties without your consent.
            </strong>
          </li>
          <br />
          <li>
            e. Device Information. We will collect your device information with your permission, including device name, unique device
            identifier assigned to the device by the phone manufacturer, operator, IMEI/IDFA, AndroidID, IMSI, MAC address, SIM card serial
            number, device model, or operating system developer, language settings, device hardware and software information, running
            process, browser memory capacity, and sensor information (such as light sensors, gravity sensors, magnetic field sensors,
            acceleration sensors, gyroscope sensors), information of nearby devices, bluetooth and network connection data, notifications of
            your device. These information are used to identify your device, ensure that the device is not used without your authorization,
            and prevent fraudulent behavior. In addition, we may also collect a list of installed applications on your device to assess your
            future debt repayment ability. This will help us provide you with a reasonable credit limit to maximize the maintenance and
            promotion of your cash flow health.
          </li>
          <br />
          <li>
            f. Location Information. We will collect and monitor the location information of the device with your permission, including the
            GPS, longitude, latitude, location area, location area code, location mode, location time, and other location related
            information of the user device; The collection purpose is to rate users' credit and ensure the security of their accounts by
            obtaining their actual location information. For example, verifying the authenticity of user authentication information during
            registration and authentication.
          </li>
          <br />
          <li>
            g. Calendar information. We will apply for calendar access with your permission, including reading and writing in your calendar.
            In terms of reading permission, we will collect calendar information including repayment reminders, based on which we could
            conduct credit and financial risk assessment, thereby speeding up the loan application process. In terms of writing permission,
            when the payment date is confirmed by us, we will create a payment reminder on your device calendar.
          </li>
          <br />
          <li>h. other additional personal data required by the government pursuant to the Law from time to time; and</li>
          <br />
          <li>i. any past or update of the above-mentioned personal data.</li>
          <ol>
            <br />
            <li>5.4 The other information of data subjects collected shall include:</li>
          </ol>
          <br />
          <li>
            a. Transaction data, including but not limited to transaction history on the Product, telecommunication service transaction
            data, anonymized mobile usage data (i.e., anonymized call &amp; SMS history, location, transaction history) that We are legally
            and authorized to process from other relevant third party;
          </li>
          <br />
          <li>b. Academic data;</li>
          <br />
          <li>c. Data derived from the use of our services (use, configuration and interaction)</li>
          <br />
          <li>d. Credit history;</li>
          <br />
          <li>
            e. Financial data, including but not limited to bank, bank account number, social insurance history, and credit card number;
          </li>
          <br />
          <li>
            f. data relating to any customer service requests You may raise or complaints You may have in order to resolve them, including
            audio recordings collected when You contact Our customer care unit;
          </li>
          <br />
          <li>
            g. other information which We may need to collect, depending on the specific Cashpaddie contest, event, campaign, form, feature
            or other service that You use or request;
          </li>
          <br />
          <li>h. GooglePlay account information, Apple Appstore or other download Products.</li>
          <br />
          <li>
            i. Social media account information including but limited to Facebook, Instagram, Twitter, which filled by Users when using the
            Product’s Services;
          </li>
          <br />
          <li>
            j. data relating to any customer service requests You may raise or complaints You may have in order to resolve them, including
            audio recordings collected when You contact Our customer care unit and/or audio recording; and
          </li>
          <br />
          <li>k. other additional data, description, information and/or documents required by the government pursuant to the Law.</li>
          <ol>
            <br />
            <li>
              5.5 The User acknowledge that the relevancy of collecting the type of Personal Data as mentioned in this article is for
              processing the User’ Personal Data as described in Article 7 on Disclosure of Information We Collect, Article 8 on Purpose or
              any article herein.
            </li>
          </ol>
          <br />
          <li>
            <strong className="paragraph-title">6. How We Collect Information </strong>
            <br />
            <br />
            <ol>
              <li>
                6.1 Cashpaddie may collect Personal Data directly from you or indirectly from other external sources to enable us to fulfill
                our mandate as a Product to process your request and to comply regulatory requirements. We may collect this Personal Data
                through:
              </li>
            </ol>
          </li>
          <li>a. User registration: users disclose its Personal Data to us during registration in Cashpaddie;</li>
          <br />
          <li>
            b. Collected by Product: Information automatically collected: i) when you use our Product, including technical data such as IP
            address, usage activity and Transaction Data; ii) when the User makes payments in our Product, including Cashpaddie, the
            information generated from the use of the Cashpaddie Product, which is automatically collected from the User; iii) when the User
            interacts with the Cashpaddie Product, and/or iv) other information accessed through the API;
          </li>
          <br />
          <li>
            c. Collection from Cashpaddie's third parties: information obtained from other Cashpaddie's third parties in compliance with
            applicable laws and regulations;
          </li>
          <br />
          <li>d. Collection from other Cashpaddie's registered Users;</li>
          <br />
          <li>e. Public available source as permitted by applicable laws and regulations.</li>
        </ol>
        <p>Therefore, Cashpaddie will request access permission to your camera, location, and microphone.</p>
        <ol>
          <li>
            6.2 Cashpaddie may collect and process access information on the User’s approximate location, camera, and microphone and the
            Personal Data when the User uses the Product with the prior User’s permission, provided that:
          </li>
        </ol>
        <ol>
          <li>
            a. Cashpaddie shall be prohibited from accessing and reading images, contacts of Users, external storage, fine location, and/or
            videos;
          </li>
          <br />
          <li>
            b. Cashpaddie shall not access, collect, use and share the Personal Data and the Access acquired through the Product for the
            purpose other than Article 7 on Disclosure of Information We Collect and Article 8 on Purpose unless otherwise required or
            exempted by applicable laws and regulations in compliance to the Google Policy amended, changed and modified from time to time;
          </li>
          <br />
          <li>
            c. Cashpaddie may also transfer data as necessary to service providers or for legal reasons such as to comply with a valid
            governmental order, request, court decision, Governing Law, or as part of a merger or acquisition with proper notification to
            Users.
          </li>
          <br />
          <li>
            <strong className="paragraph-title">7. Disclosure of Information We Collect</strong>
          </li>
        </ol>
        <p>
          Cashpaddie is committing to keep your information confidential. However, you agree that Cashpaddie may disclose, transfer and/or
          give access to your Information to parties that we deem necessary in connection with our Services and/or for regulatory compliance
          purposes for their Processing (if applicable) in connection with one or more purposes as set out in this Privacy Policy and in
          accordance with applicable laws and regulations, including but not limited to:
        </p>
        <ol>
          <li>7.1 directors, officers, employees and/or affiliates of Cashpaddie;</li>
          <br />
          <li>7.2 Authorised third party service providers with whom Cashpaddie works, including:</li>
        </ol>
        <ol>
          <li>a. Payment service providers;</li>
          <br />
          <li>b. Electronic signature service providers;</li>
          <br />
          <li>c. electronic know-your-customer service providers;</li>
          <br />
          <li>d. Messaging-as-a-Service API providers;</li>
          <br />
          <li>e. credit scoring service providers;</li>
          <br />
          <li>f. anti fraud &amp; risk control provider;</li>
          <br />
          <li>g. any aggregator or other financial digital innovation provider;</li>
          <br />
          <li>h. loan application/credit history provider;</li>
          <br />
          <li>i. AI communication provider;</li>
          <br />
          <li>j. Advertising provider;</li>
          <br />
          <li>k. web and mobile analytics provider;</li>
          <br />
          <li>l. Message/call channeling service provider; and/or</li>
          <br />
          <li>
            m. other relevant and applicable service in compliance with Nigeria laws and regulations that will help Cashpaddie's to improve
            its Service and Product.
            <ol>
              <br />
              <li>7.3 Banks;</li>
              <br />
              <li>7.4 Third-party money lenders;</li>
              <br />
              <li>
                7.5 Business partner for channeling cooperation, joint marketing cooperation and/or other parties relevant to our business
                operations;
              </li>
              <br />
              <li>
                7.6 Courts, prosecutors, police, other government institutions either at the initiative of Cashpaddie or as required by
                legal provisions and regulations, directives, instructions or policies of government institutions or government officials;
              </li>
              <br />
              <li>
                7.7 Any member of Our group (which means Our subsidiaries, Our affiliates, Our ultimate holding company and its
                subsidiaries) from time to time for one or more of the purpose;
              </li>
              <br />
              <li>7.8 The Authorised government institution under Nigerian law;</li>
              <br />
              <li>
                7.9 Other registered Users of Cashpaddie when checking updates of Your Personal Information or alternative Personal
                Information in our Product; and
              </li>
              <br />
              <li>7.10 Any party authorized and/or approved by you.</li>
            </ol>
          </li>
        </ol>
        <p>
          We may need to disclose Personal Data to third party service providers, agents, our affiliated companies, and/or other third
          parties located outside the territory of the Republic of Nigeria. Nonetheless, such third-party service providers, agents and/or
          affiliates or related companies and/or other third parties shall only manage and/or utilize Personal Data in connection with one
          or more purposes as set out in this Privacy Policy and in accordance with applicable laws and regulations.
        </p>
        <ol>
          <li>
            <strong className="paragraph-title">8. Purpose </strong>
          </li>
          <br />
          <li>
            8.1 User hereby agree that Cashpaddie is entitled to request, obtain, collect, process, analyse, rectify, update, store,
            disclose, display, announce, transfer, disclosure and/or use one or more information we collect of data subject, in the
            framework of or related to for one or more of the below purpose as permitted by laws and regulation is as follows:
          </li>
          <br />
          <li>
            a. Sending one of User’s Information to the relevant third party for the purpose of providing Cashpaddie's service to User,
            including but not limited to: i) registration and credit scoring; ii) OCR provider; iii) electronic signature provider as
            disclosed in Article 7;
          </li>
          <br />
          <li>
            b. Assessment, analysis, verification, validation or examination of (i) application registration; (ii) a loan application and
            line of credit; (ii) the process or application for registration; and/or (iii) User profile, by using any methods from
            Cashpaddie;
          </li>
          <br />
          <li>
            c. Carrying out statistical analysis, credit, risk and anti-money laundering screening, conduct credit checks, reviews and other
            backgrounds checks and/or risk profile and keep history of Your credit for current and future reference;
          </li>
          <br />
          <li>
            d. Comparing information, and verify with the authorized third party service provider whom we may cooperate with to ensure that
            the information is accurate;
          </li>
          <br />
          <li>e. Contacting you to determine the availability of the contacts You provide and the fraud-risk of Your application;</li>
          <br />
          <li>
            f. Requesting the update of User’s Personal Information or alternative Personal Information from User, third parties or other
            Users;
          </li>
          <br />
          <li>
            g. Monitoring, detecting, prevent, verify and investigate suspicious transactions or transactions that are indicated to contain
            elements of fraud, crimes or violations of the Terms and Conditions or applicable legal provisions, as well as taking necessary
            actions as a follow-up to the results of monitoring or investigating such transactions;
          </li>
          <br />
          <li>h. Helping us detect and fight abuse, malicious acts, fraud, spam, and illegal activity on the Product;</li>
          <br />
          <li>
            i. conducting investigations under the prevailing laws including anti-money laundering and prevention of terrorist financing in
            the financial services sector;
          </li>
          <br />
          <li>
            j. Creating, manage, train and/or develop credit assessment models, including but not limited to artificial intelligence or
            machine learning models and algorithms in connection with the services and products of Cashpaddie or any other
            cooperative/related third-parties;
          </li>
          <br />
          <li>
            k. Calling and/or send you our marketing and/or promotional Materials from time to time through short message service (SMS),
            Whatsapp (WA), email, including calling the registered Users by AI Robot;
          </li>
          <br />
          <li>
            l. Sending newsletters/information from/about the service/products of us, our related companies and any third parties that may
            be of interest to you;
          </li>
          <br />
          <li>m. Sharing Information to cooperative/related third parties for them to promote and/ or provide their products to you;</li>
          <br />
          <li>
            n. Introducing or recommending third-party credit Products and/or third-party money lenders to users thus providing suitable or
            comprehensive services;
          </li>
          <br />
          <li>
            o. Evaluating the effectiveness of the marketing of the Product/cooperative or related third parties, including for periodic
            checks, for marketing research, evaluation, academic studies, analysis and product development. This allows Us to understand and
            analyze Your preferences and needs, and develop new features, products and services;
          </li>
          <br />
          <li>p. Sending any related loan reminder in Product to User;</li>
          <br />
          <li>q. Checking User’s emergency contact for the confirmation of User’s whereabouts;</li>
          <br />
          <li>r. Conducting audit the downloading of data from the Product;</li>
          <br />
          <li>s. Identifying You when You contact Us or visit the Product;</li>
          <br />
          <li>t. Responding and processing your inquiries, requests, complaints, and notify any change on Product and services;</li>
          <br />
          <li>
            u. Fulfillment of Cashpaddie's rights and obligations based on legal provisions and statutory regulations including but not
            limited to relevant laws and regulation on Anti-Money Laundering and Counter Financing of Terrorism, Funding Agreements and/or
            Loan Agreements;
          </li>
          <br />
          <li>v. Implementation and supervision of the Loan Agreemen;</li>
          <br />
          <li>w. Implementation of Cashpaddie's business and operational activities from time to time in good faith;</li>
          <br />
          <li>x. Survey, research, evaluation and/or development of products or services by Cashpaddie or related interested parties;</li>
          <br />
          <li>
            y. Processing, administering, managing and dealing with the Service or products that any third-party provided to Cashpaddie; and
          </li>
          <br />
          <li>z. Use Your Personal Information for any other purpose to which You have provided consent.</li>
        </ol>
        <p>
          We may request, obtain, collect, process, analyse, rectify, update, store, disclose, display, announce, transfer, disclosure
          and/or use Your Information for other purposes that do not appear above. However, We will notify You of such other purpose at the
          time of obtaining Your consent, unless processing of Your Information without Your consent is permitted by any applicable law.
        </p>
        <ol>
          <li>
            8.2 The User hereby grants Cashpaddie an irrevocable, non-exclusive, royalty-free, transferable permission to use any
            information and/or content on the Cashpaddie Product that the User installs, stores, uses, and/or inputs on or in connection
            with the Cashpaddie Product (“Permission”) during using or enjoying Cashpaddie Product.
          </li>
          <br />
          <li>
            8.3 This Permission shall continue to operate and only end if the User requests on withdrawal consent to Cashpaddie in writing
            for the purpose of deleting User information and/or content on the Cashpaddie Product, provided that such request will follow to
            Cashpaddie's internal policy relating to delete User information. Besides, it is needed to grant us a permission to ues
            notification and calendar fuctions of your device for the purpose of pushing notifications when the status of your loan changes
            and creating a loan repayment reminder on your device's calendar. Futher more, we need identify and analyze the nearby devices
            of yours with the help of collectting bluetooth and network connection data for the purpose of detecting suspicious
            activities(e.g, using the device in an unfamiliar network environment may imply that you are not operating it yourself), which
            is also agreed by the user hereby.
          </li>
          <br />
          <li>
            8.4 If Users do not agree with Us using Your Personal Information for such purposes, Users can send a written request to
            Cashpaddie. Please note should You wish to object the usage of Your Personal Information in this way, We may not be able to
            continue to offer You Our products and services, in which case We may need to terminate Our relationship in order to fulfil Your
            request.
          </li>
          <br />
          <li>
            <strong className="paragraph-title">9. Cookies Policy</strong>
          </li>
          <br />
          <li>
            9.1 Cashpaddie uses Cookies for various purposes such as when the User shall visit the Cashpaddie Product website again, count
            the number of Users, and remember Users especially if the User uses services on the Cashpaddie Product.
          </li>
          <br />
          <li>9.2 When Users access or use the Cashpaddie Product, Cashpaddie automatically shall use Cookies.</li>
          <br />
          <li>
            9.3 If Cookies are enabled, Cookies shall record a small amount of data on the Cashpaddie Product website visited by the User,
            and Cookies shall help Cashpaddie to track the features that the User is most interested in and the content that the User has
            visited before.
          </li>
          <br />
          <li>
            9.4 When the User visits Cashpaddie's website at a later time, Cookies shall make it easier for Cashpaddie to customize the
            Cashpaddie Product content to meet User's needs.
          </li>
          <br />
          <li>
            9.5 If the User does not want to send "Cookies" information about them, the User can disable User Cookies in the web browser
            function, by using "Options" in the web browser to choose not to receive Cookies on the computer hard disk or choose to request
            permission on the computer when receiving Cookies. You may not benefit from some Services on the Product if Cookies are deleted
            or prevented from being installed on your device.
          </li>
          <br />
          <li>
            9.6 Click "Help" to find out more about the method of accepting specified Cookies. If the User adjusts the User's computer
            settings which results in rejection of Cookies on the User's computer, then the User cannot enjoy all the facilities.
          </li>
          <li>
            9.7 Use of Cookies by many other companies is subject to their own privacy policies, and not this Privacy Policy. Advertisers or
            other companies do not have access to Cashpaddie's Cookies.
          </li>
          <br />
          <li>
            <strong className="paragraph-title">10. Security of Personal Information</strong>
          </li>
          <br />
          <li>
            10.1 Cashpaddie appreciates the protection of User privacy and Cashpaddie maintains the confidentiality of Information and any
            information related to User's Account based on this Privacy Policy by:
          </li>
          <br />
          <li>
            a. shall not disclose any information except as provided in Article 7 on Disclosure of Information We Collect and Article 8 on
            Purpose of this Privacy Policy;
          </li>
          <br />
          <li>b. guard technology products to prevent unauthorized computer or network access;</li>
          <br />
          <li>c. limit Cashpaddie employees who have access to User data;</li>
          <br />
          <li>d. carry out technical security efforts to secure the storage of information; and</li>
          <br />
          <li>e. continuously monitor that User Information is protected and there are safeguards in place to protect Information.</li>
          <br />
          <li>
            10.2 Cashpaddie shall take all reasonable steps and actions to prevent loss, misuse or alteration of User's Personal Information
            without permission by unauthorized parties.
          </li>
          <br />
          <li>
            10.3 Regardless of Cashpaddie's steps and precautions, unauthorized entry or use, as well as hardware or software failure, may
            occur and compromise the security of User's Personal Information. In connection with these matters Cashpaddie hereby provides a
            disclaimer that, to the fullest extent permitted by law, any direct or indirect responsibility or liability arising from or in
            connection with any loss, theft, or unauthorized access, taking, use, disclosure, copying, modifying, deleting or similar
            actions regarding Personal Information provided by Users to Cashpaddie.
          </li>
          <br />
          <li>
            10.4 When registering on the Product, the User is asked to create the User's own personal password. The user is responsible for
            maintaining the confidentiality of the password. Users are requested not to share User passwords with any party.
          </li>
          <br />
          <li>
            10.5 Despite Our measures and precautions, unauthorized entry or use, as well as hardware or software failures, may occur and
            compromise the security of Your Personal Data. In connection with these matters We hereby provide a disclaimer that, we cannot
            guarantee that the internet is 100% secure. Users’ transmission of Personal Data to and from our Funding Services is at your own
            risk. Therefore, We recommend that You should only access the Product in a secure and protected internet environment.
          </li>
          <br />
          <li>
            10.6 Users understand that we may share your Personal Data with authorized government agencies and/or other agencies that may be
            designated by government authorities. You agree and acknowledge that the storage of Your Personal Data by the relevant
            government agencies and/or other agencies will follow each party's policies regarding data storage.
          </li>
          <br />
          <li>
            10.7 Users need to pay attention that messages sent via an internet connection cannot be fully guaranteed for security and can
            be withheld, lost or changed. Cashpaddie shall not be responsible for messages that are lost, modified by third parties, or
            withheld and Cashpaddie is not materially responsible to Users or anyone for damage or other matters in connection with messages
            sent by Users to Cashpaddie or by Cashpaddie to Users via an internet connection.
          </li>
          <br />
          <li>
            <strong className="paragraph-title">11. Failure to Secure Personal Data </strong>
            <br />
            <br />
            <ol>
              <li>
                11.1 Cashpaddie is committed to safeguarding Your Personal Data and complying with the provisions of applicable laws and
                regulations.
              </li>
              <br />
              <li>
                11.2 In the event of a failure to protect Personal Data on the Product or when we become aware of it from a third party, we
                will make reasonable efforts to notify in writing via electronic mail to You with and provide the reasons or causes of the
                failure to protect your Personal Data.
              </li>
            </ol>
          </li>
          <br />
          <li>
            <strong className="paragraph-title">12. Storage of Personal Data </strong>
            <br />
            <br />
            <ol>
              <li>
                12.1 All Personal Information (including past or update Personal Information) and other Information provided by the User
                and/or received by Cashpaddie in accordance with the Privacy Policy is stored securely in the territory of the Republic of
                Nigeria and if such Personal Information is sent outside the territory of the Republic of Nigeria, the delivery shall be
                carried out in accordance with the terms and conditions of the applicable laws and regulations.
              </li>
              <br />
              <li>
                12.2 Cashpaddie will ensure all reasonable steps and measures to prevent the destruction, loss, misuse, disclosure, access
                or unauthorized alteration of your Personal Data by unauthorized parties. We protect your Personal Data by: (i) restricting
                access to Personal Data; (ii) maintaining and using technology products, including firewalls, and other security procedures
                to prevent unauthorized computer access and network access; and (iii) other reasonable cybersecurity measures.
              </li>
              <br />
              <li>
                12.3 Cashpaddie shall also take reasonable steps to protect User data during transfer and storage; steps may include
                obtaining User consent, implementing contractual protections, and using other legally recognized transfer mechanisms.
              </li>
            </ol>
          </li>
          <br />
          <li>
            <strong className="paragraph-title">13. Change, Return and Destruction of Personal Data</strong>
          </li>
          <br />
          <li>
            13.1 User's Personal Information can be destroyed at the request of the User, unless Cashpaddie is required to store it in
            accordance with statutory provisions.
          </li>
          <br />
          <li>
            13.2 Destruction of Personal Information includes removing part or all of the documents related to User Personal Information
            that Cashpaddie keeps so that the Personal Information cannot be displayed again in Cashpaddie's electronic system unless the
            User provides new Personal Information to Cashpaddie.
          </li>
          <br />
          <li>
            13.3 Cashpaddie understands that Users need to update or change User Personal Information from time to time. If the User needs
            to update or change Personal Information, the User can submit a written request via electronic mail (e-mail) to Cashpaddie and
            provide Cashpaddie with the relevant details. Cashpaddie will help update and/or correct User Personal Information for Users,
            provided that such changes are not: i) endanger the security, physical health, or mental health of the Personal Data Subject
            and/or other persons; ii) has an impact on the disclosure of Personal Data belonging to other people; and/or; iii) contrary to
            the interests of national defense and security.
          </li>
          <br />
          <li>
            <strong className="paragraph-title">14. Users’ Rights</strong>
          </li>
        </ol>
        <p>You as a User of Our Product have rights to Your Personal Data, including but not limited to:</p>
        <ol>
          <li>14.1 accepting the obligation from Us to maintain the confidentiality of Your Personal Data;</li>
          <br />
          <li>14.2 getting access to change or update their Personal Data to be accurate in the Product;</li>
          <br />
          <li>
            14.3 getting access to obtain historical Personal Data that has been provided to Us as long as it is still in accordance with
            the provisions of the applicable laws and regulations;
          </li>
          <br />
          <li>
            14.4 submitting an application for the destruction of Personal Data that We manage and store, unless otherwise determined by the
            provisions of the applicable laws and regulations;
          </li>
          <br />
          <li>
            14.5 receiving information regarding the purpose of use and disclosure of Your Personal Data by Us as mentioned in this Privacy
            Policy.
          </li>
        </ol>
        <ol>
          <li>
            <strong className="paragraph-title">15. User Responsibility</strong>
            <br />
            <br />
            <ol>
              <li>
                15.1 The User agrees that User must only submit to us your Personal Information that is accurate and not misleading and you
                must keep it up to date as well as inform us of any changes (more information below). We reserve the right to request for
                documentation to verify the information provided by you. If you provide Personal Information of any third party to us,
                including but not limited to contact information of a third party for emergency contact purposes, you warrant to us that you
                have obtained the required consent from the relevant third party to share and transfer his/her Personal Information to us.
              </li>
              <br />
              <li>
                15.2 The user agrees to release and indemnify Cashpaddie, affiliates, shareholders, directors, commissioners, employees,
                sub-contractors, providers, and or Cashpaddie's agents for any requests, lawsuits, claims, losses, damages, including legal
                consultant fees, caused by violation of this Privacy Policy, Loan Agreement, Services and/or other agreements by User and
                Cashpaddie.
              </li>
            </ol>
          </li>
          <br />
          <li>
            <strong className="paragraph-title">16. Governing Law</strong>
          </li>
          <br />
          <li>
            16.1 This Privacy Policy is governed and interpreted in accordance with the provisions of the laws of the Republic of Nigeria.
          </li>
          <br />
          <li>
            16.2 If there is a dispute in the interpretation and/or exercise of the provisions of this Privacy Policy (“Dispute”), the User
            agrees to first resolve the Dispute by deliberation to reach a consensus within 30 (thirty) days from receipt of notification of
            the Dispute.
          </li>
          <br />
          <li>
            16.3 If the Dispute cannot be resolved by deliberation for consensus within 30 (thirty) days, the User agrees to resolve the
            Dispute at the Court where QUARK is located.
          </li>
          <br />
          <li>
            <strong className="paragraph-title">17. Amendment</strong>
          </li>
          <br />
          <li>
            17.1 We reserve the right to amend this Privacy Policy at any time without advance notice unless required by law. All changes
            will be effective upon publication and shall be applicable to all users. Users are adviced to check this Privacy Policy
            periodically.
          </li>
          <br />
          <li>
            17.2 Your continued usage of the Mobile Application, Website or Services after any change of this Privacy Policy shall be deemed
            as an acceptance by you of the changed Privacy Policy.
          </li>
          <br />
          <li>
            <strong className="paragraph-title">18. Withdrawal of Consent</strong>
          </li>
        </ol>
        <p>
          You may express your objection to our continued use, collection and/or processing of your Personal Data for any lawful purpose and
          in the manner as stated at any time by contacting us at our email address. This will be your mechanism for terminating or
          permitting permissions previously granted to us. Please note that if you raise your objection to the processing of certain
          Personal Data, depending on the nature and subject of your objection, the same may impact the timeline of your application and/or
          our analysis of your credit worthiness. Our legal rights and remedies are expressly protected by law in such events.
        </p>
        <ol>
          <li>
            <strong className="paragraph-title">19. Contact Us</strong>
          </li>
        </ol>
        <p>
          If you have questions, comments and requests or other matters related to the Product, you can contact Cashpaddie's officers via:
        </p>
        <ol>
          <li>E-mail : {email}</li>
          <li>Phone : {phone}</li>
        </ol>
        <br />
      </div>
    </AgreementWrap>
  );
};

export default PrivacyPolicy;
