import styled from 'styled-components';
import { mediaQueries } from '../../mediaQueries';

const Button = styled.button`
  color: var(--white);
  background-color: var(--first-color-dark);
  border: 0;
  width: 100%;
  height: 44px;
  border-radius: 44px;
  font-size: 16px;
  font-weight: 700;

  :disabled {
    background-color: var(--first-color-disabled);
    cursor: not-allowed;
  }

  ${() =>
    mediaQueries('lg')(`
      height: 66px;
      border-radius: 66px;
      font-size: 20px;
      cursor: pointer;
  `)}
`;

interface FormButtonProps {
  children?: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
}

const FormButton: React.FC<FormButtonProps> = (props) => {
  return <Button {...props}>{props.children}</Button>;
};

export default FormButton;
